// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-page-js": () => import("/home/runner/work/digiaonline.github.io/digiaonline.github.io/src/templates/ProjectPage.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-test-page-js": () => import("/home/runner/work/digiaonline.github.io/digiaonline.github.io/src/templates/TestPage.js" /* webpackChunkName: "component---src-templates-test-page-js" */),
  "component---src-pages-404-js": () => import("/home/runner/work/digiaonline.github.io/digiaonline.github.io/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/digiaonline.github.io/digiaonline.github.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("/home/runner/work/digiaonline.github.io/digiaonline.github.io/src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */)
}

